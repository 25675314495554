<template>
  <app-card-item
    class="p-4 max-w-screen-md w-full"
  >
    <h1>{{ t('broadcast.frontpage.link_of_broadcast_page') }}</h1>

    <app-copy-clipboard
      class="rounded-lg bg-blue-100/70 px-3 py-2 mt-4"
      :text="url"
      size="16"
    />

    <a
      :href="url"
      target="_blank"
      class="app-link text-lg block mx-auto w-fit mt-5"
    >
      {{ t('broadcast.ui.link.broadcast_page') }}
    </a>
  </app-card-item>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

import AppCardItem from '@shared/components/ui/card/AppCardItem.vue'
import AppCopyClipboard from '@shared/components/ui/AppCopyClipboard.vue'

const { t } = useI18n()

defineProps({
  url: {
    type: String,
    required: true,
  },
})
</script>
