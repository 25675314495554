<template
  v-if="breadcrumbs"
>
  <ol class="flex items-center">
    <li>
      <router-link
        :to="{ name: 'dashboard' }"
        class="app-link font-medium"
      >
        {{ t('breadcrumbs.dashboard.dashboard') }}
      </router-link>

      <font-awesome-icon
        icon="chevron-right"
        size="sm"
        class="mx-2.5"
      />
    </li>

    <li
      v-for="(item, i) in breadcrumbs"
      :key="item"
    >
      <template
        v-if="item.link"
      >
        <router-link
          :to="item.to"
          class="app-link font-medium"
        >
          {{ item.text }}
        </router-link>
      </template>

      <template v-else>
        <span>
          {{ item.text }}
        </span>
      </template>

      <font-awesome-icon
        v-if="i < (breadcrumbs.length - 1)"
        icon="chevron-right"
        size="sm"
        class="mx-2.5"
      />
    </li>
  </ol>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

defineProps({
  // Array of breadcrumbs
  breadcrumbs: {
    type: Array,
    default: () => ([]),
  },
})
</script>
