<template>
  <teleport-wrapper
    to="#breadcrumbs"
  >
    <app-breadcrumb
      :breadcrumbs="breadcrumbs"
      class="mb-4"
    />
  </teleport-wrapper>

  <teleport-wrapper
    to="#mainTitle"
  >
    {{ t('breadcrumbs.broadcast.page_edition') }}
  </teleport-wrapper>

  <copy-to-clipboard
    v-if="!store.state.spinner.active"
    :url="broadcastPage.attributes?.url"
  />

  <app-card-item
    v-if="!store.state.spinner.active"
    class="p-4 max-w-screen-md mt-8"
  >
    <broadcast-form
      v-if="broadcastPage"
      :resource="broadcastPage"
      :submitting="broadcastPageFormSubmitting"
      form-mode="edit"
      :additional-errors="formErrors"
      @submitted="handleSubmit"
    />
  </app-card-item>
</template>

<script setup>
import {
  onMounted,
  ref,
  computed,
} from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'

import { fetchApiBroadcastPage, updateBroadcastPage } from '@shared/http/api'
import breadcrumbBroadcastPage from '@extranet/breadcrumbs/broadcast_page'
import AppBreadcrumb from '@extranet/components/ui/AppBreadcrumb.vue'
import AppCardItem from '@shared/components/ui/card/AppCardItem.vue'
import BroadcastForm from '@extranet/components/resources/broadcast_page/BroadcastPageForm.vue'
import CopyToClipboard from '@extranet/components/resources/broadcast_page/CopyToClipboard.vue'

const store = useStore()
const route = useRoute()
const router = useRouter()
const { t } = useI18n()

// ---------- BROADCAST_PAGE ----------

const broadcastPageFormSubmitting = ref(false)
const broadcastPage = ref({})
const formErrors = ref({})

// ---------- FETCH DATA ----------

function fetchBroadcastPage() {
  store.commit('spinner/ENABLE')

  const { id } = route.params
  const params = {
    'mode': 'edit',
  }

  fetchApiBroadcastPage(id, params)
    .then((response) => {
      broadcastPage.value = response.data.data

      if (!broadcastPage.value?.authorizations?.update) {
        router.push({ name: 'dashboard' })
        store.commit(
          'flashes/ADD_FLASH',
          {
            message: t('exceptions.403'),
            type: 'danger',
          },
        )
      }
    })
    .catch((e) => {
      if (e.response?.data?.errors) {
        formErrors.value = e.response.data.errors
      }
    })
    .finally(() => {
      store.commit('spinner/DISABLE')
    })
}

// ----------- FORM SUBMIT -----------

function handleSubmit(attributes) {
  if (!broadcastPageFormSubmitting.value) {
    broadcastPageFormSubmitting.value = true

    const params = {
      data: {
        type: 'broadcastpages',
        attributes: { ...attributes },
      },
    }

    updateBroadcastPage(broadcastPage.value.id, params)
      .then((response) => {
        const pointOfInterest = response.data.data.relationships.point_of_interest
        router.push({ name: 'show-points-of-interest', params: { id: pointOfInterest.id } })

        store.commit(
          'flashes/ADD_FLASH',
          {
            message: t('broadcast.notifications.updated'),
            type: 'success',
          },
        )
      })
      .catch((e) => {
        if (e.response?.data?.errors) {
          formErrors.value = e.response.data.errors
        }
      })
      .finally(() => {
        broadcastPageFormSubmitting.value = false
      })
  }
}

// ---------- BREADCRUMBS ----------

const { show } = breadcrumbBroadcastPage()

const breadcrumbs = computed(() => (
  show(broadcastPage.value)
))

// ---------- LIFECYCLES ----------

onMounted(() => {
  fetchBroadcastPage()
})
</script>
