<template>
  <form
    enctype="multipart/form-data"
    novalidate
    @submit.prevent="handleSubmit"
  >
    <form-global-errors />

    <fieldset
      :disabled="submitting"
    >
      <broadcast-page-form-fields
        :resource="resource"
        :form-mode="formMode"
      />

      <app-button
        class="mt-4"
        feature="save"
        type="submit"
        :disabled="submitting || invalid"
        :loading="submitting"
      />
    </fieldset>
  </form>
</template>

<script setup>
import useForm from '@shared/hooks/form/form'
import AppButton from '@shared/components/ui/AppButton.vue'
import BroadcastPageFormFields from '@extranet/components/resources/broadcast_page/BroadcastPageFormFields.vue'
import FormGlobalErrors from '@shared/components/form/FormGlobalErrors.vue'

const props = defineProps({
  // JSON API resource used to
  // populate the form fields
  resource: {
    type: Object,
    default: () => ({}),
  },
  // Is the form currently submitting
  // via an ajax request
  submitting: {
    type: Boolean,
    default: false,
  },
  // Form is in create or edit mode?
  formMode: {
    type: String,
    default: 'create',
  },
  // Additional form errors not generated
  // by VeeValidate rules
  additionalErrors: {
    type: Object,
    default: () => ({}),
  },
})

const emits = defineEmits([
  'submitted',
])

const {
  handleSubmit,
  invalid,
} = useForm(props, { emits })
</script>
